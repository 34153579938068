<template>
  <div class="flex flex-row">
    <div class="w-1/2 ml-2 mr-1 my-2 border-2 rounded-lg border-gray-200">
      <div class="flex flex-row">
        <div class="mt-5 px-4 text-blue-700 text-3xl">
          <i class="fal fa-user"></i>
        </div>
        <div class="pt-3 pl-2">
          <div class="text-gray-600 text-2xl font-bold">Customer</div>
          <div class="text-gray-600 text-sm">Search for customer in your CRM, or add a new customer.<br>&nbsp;</div>
        </div>
      </div>
      <div class="flex flex-col items-center mt-8 px-12">
        <div class="w-full">
          <div class="mt-1 flex rounded shadow-sm">
            <div class="relative flex items-stretch flex-grow focus-within:z-10">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-500">
                <i class="fal fa-stamp"></i>
              </div>
              <input v-model="vatNumber" type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300" placeholder="VAT Number">
            </div>
            <button @click="searchVat" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
              <i class="fal fa-search"></i>
              <span>Search</span>
            </button>
          </div>
          <div class="mt-2 rounded shadow-sm">
            <input v-model="companyName"  type="text" placeholder="Company name" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded sm:text-sm border-gray-300">
          </div>
        </div>
        <div class="mt-6 w-full">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center text-sm leading-5">
              <span class="px-2 bg-white text-gray-800">
                Or search with
              </span>
            </div>
          </div>
        </div>
        <div class="mt-6 w-full">
          <div class="mt-1 rounded shadow-sm">
            <input v-model="firstName" type="text" placeholder="First name" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
          </div>
          <div class="mt-1 rounded shadow-sm mt-2">
            <input v-model="lastName" type="text" placeholder="Last name" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
          </div>
          <div class="mt-1 flex flex-row mt-2">
            <div class="rounded-sm shadow-sm w-1 w-1/4">
              <input v-model="postCode"  placeholder="Postcode" class="w-full appearance-none block px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            </div>
            <div class="ml-2 rounded-sm shadow-sm w-3/4">
              <input v-model="city" type="text" placeholder="City" class="w-full appearance-none block px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            </div>
          </div>
        </div>
        <div class="mt-6 w-48 mb-6 flex flex-col items-center">
          <button @click="searchCustomer" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
            <i class="fas fa-search mt-1 mr-2"></i> Search
          </button>
          <a @click="resetCustomer" class="mt-2 font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150 cursor-pointer">
            Reset
          </a>
        </div>
      </div>
    </div>
    <div class="w-1/2 ml-1 mr-2 my-2 border-2 rounded-lg border-gray-200">
      <div class="flex flex-row">
        <div class="mt-5 px-4 text-blue-700 text-4xl">
          <i class="fal fa-car"></i>
        </div>
        <div class="pt-3 pl-2">
          <div class="text-gray-600 text-2xl font-bold">Vehicle</div>
          <div class="text-gray-600 text-sm">Search for a car by VIN, License plate, ...<br>&nbsp;</div>
        </div>
      </div>
      <div class="flex flex-col items-center mt-8 px-12">
        <div class="w-full">

          <div class="mt-1 flex rounded shadow-sm">
            <div class="relative flex items-stretch flex-grow focus-within:z-10">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-500">
                <i class="fal fa-barcode-read"></i>
              </div>
              <autocomplete-vin title="Vin number, Chassis" v-on:selected="selectVin" v-on:change="changeVin" />
            </div>
            <button class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
              <i class="fal fa-search"></i>
              <span>Search</span>
            </button>
          </div>
        </div>
        <div class="mt-6 w-full">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center text-sm leading-5">
              <span class="px-2 bg-white text-gray-800">Or Start with</span>
            </div>
          </div>
        </div>
        <div class="mt-6 w-full">
          <div class="mt-1 flex rounded shadow-sm">
            <div class="relative flex items-stretch flex-grow focus-within:z-10">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-500">
                <i class="fal fa-brackets"></i>
              </div>
              <input v-model="registration" type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300" placeholder="License plate">
            </div>
            <button class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
              <i class="fal fa-search"></i>
              <span>Search</span>
            </button>
          </div>
        </div>
        <div class="mt-6 w-48 mb-6 flex flex-col items-center">
          <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out" v-on:click="startInspection">
            Start new trade in
          </button>
          <div class="mt-4 font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150 cursor-pointer" v-on:click="startManualInspection">Manual configuration</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from '@/components/autocomplete-vin';
import axios from 'axios';

export default {
  name: "Start",
  props: {

  },
  components: {
    'autocomplete-vin': Autocomplete
  },
  data: function() {
    return {
      vin: '',
      registration: '',
      vatNumber: '',
      firstName: '',
      lastName: '',
      postCode: '',
      companyName: '',
      city: '',
    }
  },
  methods: {
    selectVin(vin) {
      this.$store.commit('tradein/setVin', vin);
      this.$store.commit('seller/setLoading', true);
      this.vin = vin;
      axios.get("/manager/fleet/eurotax/vehicle/0/",{params: {vin: this.vin}}).then(response => {
        if(response.status===200) this.$store.commit('tradein/setVersions',  response.data);
        if(response.data.length===0) {
          this.$store.commit('tradein/setPart', 'vin-not-found')
        } else if(response.data.length>1) {
          this.$store.commit('tradein/setPart', 'select-version')
        } else {
          this.$store.dispatch("seller/selectVersion", response.data[0]);
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.$store.commit('seller/setLoading', false);
      });
    },
    changeVin (vin) {
      this.vin = vin;
    },
    startInspection() {
      this.$store.commit('tradein/setPart', 'select-make')
    },
    startManualInspection() {
      this.$store.commit("seller/newItem", 0);
    },
    resetVehicle() {
      this.vin = "";
      this.registration = "";
    },
    searchVat() {
      this.$store.commit('tradein/setCustomerSearch', {
        part: 'vat',
        vatNumber: this.vatNumber
      });
      this.$store.commit('tradein/setPart', 'select-customer');
    },
    searchCustomer() {
      this.$store.commit('tradein/setCustomerSearch', {
        part: 'customer',
        companyName: this.companyName,
        firstName: this.firstName,
        lastName: this.lastName,
        postCode: this.postCode,
        city: this.city
      });
      this.$store.commit('tradein/setPart', 'select-customer');
    },
    resetCustomer() {
      this.firstName = '';
      this.lastName = '';
      this.postCode = '';
      this.city = '';
      this.vatNumber = '';
      this.companyName = '';
    }
  },
}
</script>

<style scoped>

</style>