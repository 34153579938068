<template>
  <div class="w-full" v-click-outside="comboOutsideClick" >
    <input  v-model="searchQuery" v-on:keyup="autoComplete" type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300" placeholder="Vin number">
    <div :class="['absolute mt-1 w-56 rounded shadow-lg', open ? '' : 'hidden', right ? 'origin-top-right right-0' : '']" style="max-height: 300px; overflow-y: auto;">
      <div class="rounded bg-white shadow-xs">
        <div class="py-1">
          <a v-for="item in items" :key="item.id" href="#" class="block px-2 py-1 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" v-on:click="select(item)">
            {{ item.label }}
          </a>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import axios from 'axios';
import ClickOutside from 'vue-click-outside';

export default {
  name: "autocomplete-vin",
  props: {
    icon: String,
    title: String,
    right: Boolean
  },
  data: function() {
    return {
      searchQuery: '',
      open: false,
      items: []
    }
  },
  watch: {
    searchQuery: function() {
      this.$emit('change', this.searchQuery)
    }
  },
  methods: {
    autoComplete: function() {
      this.items = [{label: "Loading ...", id: null, url: null}];
      this.open = true;
      if(![6, 17].includes(this.searchQuery.trim().length)) {
        this.open = false;
        return;
      }
      axios.get('/manager/fleet/searchVin/',{params: {term: this.searchQuery}}).then(response => {
        console.log(response);
        if(response.status===200) {
          this.items = response.data;
          this.open = this.items.length > 0;
        }
        else {
          this.items = []
          this.open = false;
        }
      });
    },
    comboOutsideClick: function() {
      this.open = false;
    },
    select: function(item) {
      this.searchQuery = item.label;
      this.open = false;
      this.$emit('selected', item.label);
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style scoped>

</style>